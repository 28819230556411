<template>
    <div class="px-1 py-2">
        <v-hover>
            <template v-slot:default="{ hover }">
                <v-card class="d-flex" :width="$route.name === 'waivers' ? 170 : 140" height="50">
                    <v-img width="80" height="40" contain :src="airline.imageUrl"></v-img>
                    <h2 class="body-2 mr-5 mt-5 primary--text text-center">
                        {{airline.code}}
                    </h2>

                    <v-fade-transition>
                        <v-overlay
                        v-if="hover"
                        absolute
                        color="#000000"
                        >
                        <v-btn @click="changeRoute(airline.code)" color="blueDark" small tile elevation="0"><span class="text-truncate">{{airline.name.split(' ')[0] + ' ' + airline.name.split(' ')[1]}}</span></v-btn>
                        </v-overlay>
                    </v-fade-transition>
                </v-card>
            </template>
        </v-hover>
    </div>
</template>

<script>
export default {
  props: ['airline'],
  data () {
    return {}
  },
  methods: {
    changeRoute (code) {
      if (this.$route.name === 'waivers') {
        this.$router.push('/waivers/' + code)
      } else {
        this.$router.push('/contracts/' + code)
      }
    }
  }
}
</script>
