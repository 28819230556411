<template>
    <v-container>
        <div v-if="$store.state.user" class="d-flex justify-space-between">
            <h3 class="text-h6 blueDark--text">Waivers airlines</h3>
            <v-btn v-if="$store.state.user.role === 'ats_admin'" elevation="0" color="blueDark" class="mr-15" small dark @click="$router.push({name: 'addWaiver'})"><v-icon left>mdi-plus-circle</v-icon>add waiver</v-btn>
        </div>
        <div v-if="!loadingAirlines && airlines.length">
            <div class="d-flex flex-wrap" >
                <airline-card v-for="item in airlines" :key="item.code" :airline="item" />
            </div>
        </div>
        <div v-else-if="!loadingAirlines && !airlines.length">
            <p class="headline my-10 text-center"><v-icon>mdi-alert</v-icon> No Airlines found!</p>
        </div>
    </v-container>
</template>

<script>
import airlineCard from '@/components/contracts/airlineCard'
import { waiversAirlines, headers } from '../../../links'

export default {
  name: 'contractlist',
  components: {
    'airline-card': airlineCard
  },
  data () {
    return {
      loadingAirlines: true,
      airlines: [],
      lastUpadatedCotracts: []
    }
  },
  created () {
    this.$store.dispatch('setLoadingState', true)
    this.$http.get(waiversAirlines, { headers: headers(this.$cookies.get('userToken')) }).then(response => {
      console.log(response)
      this.airlines = response.body.data
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      this.loadingAirlines = false
      this.$store.dispatch('setLoadingState', false)
    })
  }
}
</script>
